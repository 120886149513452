<template>
	<div>
		
		<CCard class="p-4">
		<CRow>
			
		<CCol lg="6">
					<h5>Bookings Report ({{count}})</h5>
			</CCol>
	     		 <CCol lg="6"> 
	     		 	<CButton size="sm" style="float: right;" :class="'float-lg-right block '+showCollapse ? 'collapsed' : null"
			                 :aria-expanded="showCollapse ? 'true' : 'false'"
			                 aria-controls="collapse1"
			                 @click="showCollapse = !showCollapse"
			                 color="info">Filter<i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
					<CButton
						v-c-tooltip="'Download in CSV format. Faster download, no row styling.'"  
						v-if="config.getPermission('booking_reports').download"
						size="sm" style="margin-right: 10px" color="primary" 
						@click="download('csv')" class="float-lg-right">
						<i class="fa fa-download"></i>Download (.csv)	
						
					</CButton>
			        <CButton  
							v-c-tooltip="'Download in XLSX format. May take longer time to download.'"
							v-if="config.getPermission('booking_reports').download"
							size="sm" style="margin-right: 10px" color="primary" 
							@click="download('xlsx')" class="float-lg-right">
							<i class="fa fa-download"></i>Download (.xlsx)
							
					</CButton>
			    </CCol>
		</CRow>
		<CRow> 
				<CCol lg="12">
					<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
				        <CCard class="p-4">
				          <Search @depot-search-query="search"/>
				        </CCard>
				      </CCollapse> 
				</CCol>
		</CRow> 
		<hr>

		<CRow>			 
		      <CCol lg="12" >  
				<div class="table-responsive-sm table-responsive-md">
	      		<CTabs variant="pills" :active-tab="active_tab">
	      			<CTab title="All" @click.native="tabChanged($event, 0)">
				    	<br/>
						<!-- <CCol lg="12" class="text-center" v-if="all_isloading"> 
							<div class="spinner-border m-5" role="status">
								<span class="sr-only">Loading...</span>
							</div>
						</CCol> -->
				      	<CDataTable 
				      		:loading="all_isloading"
					      	:items="dataList.data"
					      	:fields="fields"
							hover
							striped
							outlined
							small
							fixed
							
					    >  
						
					    	<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
					      	</template>
					      
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPage"
				          	:pages="Math.ceil(dataList.total / 20)"
				          	:activePage="currentPage"
				          	@update:activePage="updatePage"
				        />
				    </CTab>
				    <CTab title="Under Review" @click.native="tabChanged($event, 1)">
				    	<br/>
						<CCol lg="12" class="text-center" v-if="isLoading"> 
							<div class="spinner-border m-5" role="status">
								<span class="sr-only">Loading...</span>
							</div>
						</CCol>
				      	<CDataTable 
				      		v-else
					      	:items="dataListUnderReview.data"
					      	:fields="fields"
							hover
							striped
							outlined
							small
							fixed
					    >   
						<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
					      	</template>
					      
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPageUnderReview"
				          	:pages="Math.ceil(dataListUnderReview.total / 20)"
				          	:activePage="currentPageUnderReview"
				          	@update:activePage="updatePageUnderReview"
				        />
				    </CTab>

				    <CTab title="Approved" v-if="company_type !== 'trucking'" @click.native="tabChanged($event, 2)">
				    	<br/>
						<CCol lg="12" class="text-center" v-if="isLoading"> 
							<div class="spinner-border m-5" role="status">
								<span class="sr-only">Loading...</span>
							</div>
						</CCol>
				      	<CDataTable 
				      		v-else
					      	:items="dataListApproved.data"
					      	:fields="fields"
							hover
							striped
							outlined
							small
							fixed
							
					    >   
							<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
					      	</template>
					      
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPageApproved"
				          	:pages="Math.ceil(dataListApproved.total / 20)"
				          	:activePage="currentPageApproved"
				          	@update:activePage="updatePageApproved"
				        />
				    </CTab>

				  
				    <CTab title="Dispatched" @click.native="tabChanged($event, 3)">
				    	<br/>
						<CCol lg="12" class="text-center" v-if="isLoading"> 
							<div class="spinner-border m-5" role="status">
								<span class="sr-only">Loading...</span>
							</div>
						</CCol>
				      	<CDataTable 
				      		v-else
					      	:items="dataListDispatched.data"
					      	:fields="fields"
							hover
							striped
							outlined
							small
							fixed
							
					    >   
							<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
					      	</template>
					      	
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPageDispatched"
				          	:pages="Math.ceil(dataListDispatched.total / 20)"
				          	:activePage="currentPageDispatched"
				          	@update:activePage="updatePageDispatched"
				        />
				    </CTab>

				    <CTab title="Delivered" @click.native="tabChanged($event, 4)">
				    	<br/>
						<CCol lg="12" class="text-center" v-if="isLoading"> 
							<div class="spinner-border m-5" role="status">
								<span class="sr-only">Loading...</span>
							</div>
						</CCol>
				      	<CDataTable 
				      		v-else
					      	:items="dataListDelivered.data"
					      	:fields="fields"
							hover
							striped
							outlined
							small
							fixed
					
					    >   
							<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
					      	</template>
					      
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPageDelivered"
				          	:pages="Math.ceil(dataListDelivered.total / 20)"
				          	:activePage="currentPageDelivered"
				          	@update:activePage="updatePageDelivered"
				        />
				    </CTab>


				    <CTab title="Completed" @click.native="tabChanged($event, 5)">
				    	<br/>
						<CCol lg="12" class="text-center" v-if="isLoading"> 
							<div class="spinner-border m-5" role="status">
								<span class="sr-only">Loading...</span>
							</div>
						</CCol>
				      	<CDataTable 
				      		v-else
					      	:items="dataListCompleted.data"
					      	:fields="fields"
							hover
							striped
							outlined
							small
							fixed
							custom
					    >   
							<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
					      	</template>
					      
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPageCompleted"
				          	:pages="Math.ceil(dataListCompleted.total / 20)"
				          	:activePage="currentPageCompleted"
				          	@update:activePage="updatePageCompleted"
				        />
				    </CTab>
					<CTab title="Cancelled" @click.native="tabChanged($event, 6)">
				    	<br/>
						<CCol lg="12" class="text-center" v-if="isLoading"> 
							<div class="spinner-border m-5" role="status">
								<span class="sr-only">Loading...</span>
							</div>
						</CCol>
				      	<CDataTable 
				      		v-else
					      	:items="dataListCancelled.data"
					      	:fields="fields"
							hover
							striped
							outlined
							small
							fixed
							custom
					    >   
							<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
					      	</template>
					      
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPageCancelled"
				          	:pages="Math.ceil(dataListCancelled.total / 20)"
				          	:activePage="currentPageCancelled"
				          	@update:activePage="updatePageCancelled"
				        />
				    </CTab>
					  <CTab title="Declined" @click.native="tabChanged($event, 7)">
				    	<br/>
						<CCol lg="12" class="text-center" v-if="isLoading"> 
							<div class="spinner-border m-5" role="status">
								<span class="sr-only">Loading...</span>
							</div>
						</CCol>
				      	<CDataTable 
				      		v-else
					      	:items="dataListDeclined.data"
					      	:fields="fields"
							hover
							striped
							outlined
							small
							fixed
					    >   
						
							<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
					      	</template>
					      
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPageDeclined"
				          	:pages="Math.ceil(dataListDeclined.total / 20)"
				          	:activePage="currentPageDeclined"
				          	@update:activePage="updatePageDeclined"
				        />
				    </CTab>



				     
				</CTabs> 
				   </div>
			</CCol>
	    </CRow>
<!-- 	     
        <ApproveModal :details="selectedRow" @showModal="approveModal = $event; reloadData();" :showModal="approveModal" />
        <DispatchModal :details="selectedRow" @showModal="dispatchModal = $event; reloadData();" :showModal="dispatchModal" />
        <DeliverModal :details="selectedRow" @showModal="deliverModal = $event; reloadData();" :showModal="deliverModal" />
        <CompleteModal :details="selectedRow" @showModal="completeModal = $event; reloadData();" :showModal="completeModal" /> -->
		</CCard>
	</div>
</template>
<script> 
import config from '../config.js';
import axios from '../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import ApproveModal from './status_modal/approve';
import DispatchModal from './status_modal/dispatch';
import DeliverModal from './status_modal/deliver';
import CompleteModal from './status_modal/complete';
import moment from 'moment';
import '../style.css';

import Loader from '../../loader.mixin.js';

export default {
	mixins: [Loader],
	mounted(){  
		this.getVendor();
		this.getDepot();
		this.getData(); 
		// this.getDataUnderReview();
		// this.getDataApproved();
		// this.getDataDeclined();
		// this.getDataDispatched();
		// this.getDataDelivered();
		// this.getDataCompleted();
		// this.getDataCancelled();
		for(var i = 0; i < document.querySelectorAll('.nav-item').length; i++){
			document.querySelectorAll('.nav-item')[i].onclick = ()=>{
				this.current_tab = document.querySelector(".nav-link.active").innerHTML;
				this.setCount();
			}
		}
		
		
	},
	data(){
		return{
			msg: 'Loading',
			url : '',
			config,
			isLoading: false,
			all_isloading : false,
			approveModal: false,
			dispatchModal: false,
			deliverModal: false,
			completeModal: false,
			formModal: false,
			showViewModal: false,
			dataList: [], 
			dataListUnderReview: [], 
			dataListApproved: [], 
			dataListDeclined: [], 
			dataListDispatched: [], 
			dataListDelivered: [], 
			dataListCompleted: [], 
			dataListCancelled: [], 
			current_tab: "All",
			count: 0,
			company_type: JSON.parse(window.localStorage.getItem("user_data")).company.company_type.includes("Trucking") ? "trucking" : JSON.parse(window.localStorage.getItem("user_data")).company.company_type,
			active_tab : 0,
			depotName: "", 
			fields: [
				{
					key: 'booking_no', 
					label: 'Booking #'
				},
				{
					key: 'delivery_date_label', 
					label: 'Delivery Date'
				}, 
				{
					key: 'customer_id_label', 
					label: 'Customer'
				},
				{
					key: 'booking_type_id_label', 
					label: 'Type',
					_style: { width: '80%'}
				},
				{
					key: 'commodity_id_label', 
					label: 'Commodity',
					
				},
				{
					key: 'depot_id_label', 
					label: 'Depot'
				},  
					{
					key: 'origin_id_label', 
					label: 'Origin'
				},  
				// {
				// 	key: 'asset_type', 
				// 	label: 'Asset'
				// },
				{
					key: 'vehicle_type_id_label', 
					label: 'Trucker'
				},  
				
				{
					key: 'asset_no', 
					label: 'Plate #'
				},  
				
				{
					key: 'client_ref_no_label', 
					label: 'Client Ref #'
				},
			
				
				
				{
					key: 'status', 
					label: 'Status'
				}, 
				{
					key: 'total_amount', 
					label: 'Client Rate'
				},

			
			],
			currentPage: 1, 
			currentPageUnderReview: 1,
			currentPageApproved: 1,
			currentPageDeclined: 1,
			currentPageDispatched: 1,
			currentPageDelivered: 1,
			currentPageCompleted: 1,
			currentPageCancelled: 1,


			editMode: false,
			selectedRow: {},
			showCollapse: false,
			filter: {
				booking_no: "",
	            booking_type_id: "",
	            customer_id: "",
	            asset_type: "",
	            origin_id: "",
				depot_id: "",
				vendor_id: "",
				client_ref_no: "",
	            delivery_date_to: new Date(),
	            delivery_date_from: moment(new Date()).subtract(1, 'month')._d, 
	            plate_no: "",
				commodity_id: "",
			},
			vendorList: {
				data: []
			},
			depotList: {
				data:[]
			},
			booking_status : ''
		}
	},
	name: 'Tables',
	components: {Search, Datepicker, vSelect},
	methods: {
		tabChanged(ev, index) {
            if(ev.target.className != 'nav-link active') return; // if dili tab ang giclick

			let has_approved_tab = this.company_type !== 'trucking';

            this.active_tab = (!has_approved_tab && index >= 3 ) ? index - 1 : index;

			this.currentPage = 1;
            
            switch (index) {
                case 0:
					this.booking_status = ''
                    this.getData(); 
                    break;
                case 1:
					this.booking_status = 'under review'
                    this.getDataUnderReview(); 
                    break;
                case 2:
					this.booking_status = 'approved'
                    this.getDataApproved(); 
                    break;
                case 3:
					this.booking_status = 'dispatched'
                    this.getDataDispatched(); 
                    
                    break;
                case 4:
					this.booking_status = 'delivered'
                    this.getDataDelivered(); 
                    
                    break;
                case 5:
					this.booking_status = 'completed'
                    this.getDataCompleted(); 
                    
                    break;
                case 6:
					this.booking_status = 'cancelled'
                   this.getDataCancelled(); 
                    break;
                case 7:
					this.booking_status = 'declined'
                    this.getDataDeclined(); 
                    break;
                default:
                    break;
            }
            
        },
		setCount(){
			if(this.current_tab == "All"){
				this.count = this.dataList.total
			}
			if(this.current_tab == "Under Review"){
				this.count = this.dataListUnderReview.total
			}
			if(this.current_tab == "Approved"){
				this.count = this.dataListApproved.total
			}
			if(this.current_tab == "Declined"){
				this.count = this.dataListDeclined.total
			}
			if(this.current_tab == "Dispatched"){
				this.count = this.dataListDispatched.total
			}
			if(this.current_tab == "Delivered"){
				this.count = this.dataListDelivered.total
			}
			if(this.current_tab == "Completed"){
				this.count = this.dataListCompleted.total
			}
			if(this.current_tab == "Cancelled"){
				this.count = this.dataListCancelled.total
			}
		},
		reloadData(){
			this.getData(); 
			this.getDataUnderReview();
			this.getDataApproved();
			this.getDataDeclined();
			this.getDataDispatched();
			this.getDataDelivered();
			this.getDataCompleted();
			this.getDataCancelled();
		},
		 getBadge (status) {
    	return status === 'completed' ? 'success'
       		 : status === 'under review' ? 'dark'
         	 : status === 'approved' ? 'warning'
			 : status === 'cancelled' ? 'secondary'
			 : status === 'delivered' ? 'primary'
         	 : status === 'dispatched' ? 'info'
             : status === 'declined' ? 'danger' : 'primary'
    },
		updatePage(data){
			this.currentPage = data;
			this.getData();
		},   

		updatePageUnderReview(data){
			this.currentPageUnderReview = data;
			this.getDataUnderReview();
		},   

		updatePageApproved(data){
			this.currentPageApproved = data;
			this.getDataApproved();
		},   

		updatePageDeclined(data){
			this.currentPageDeclined = data;
			this.getDataDeclined();
		},   

		updatePageDispatched(data){
			this.currentPageDispatched = data;
			this.getDataDispatched();
		},   

		updatePageDelivered(data){
			this.currentPageDelivered = data;
			this.getDataDelivered();
		},   

		updatePageCompleted(data){
			this.currentPageCompleted = data;
			this.getDataCompleted();
		},   

		updatePageCancelled(data){
			this.currentPageCancelled = data;
			this.getDataCancelled();
		},   

	    toggleModal(){
	    	this.formModal = !this.formModal;
	    }, 

	    search(event){
	    	this.filter = event; 

			let has_approved_tab = this.company_type !== 'trucking';

            let index = (!has_approved_tab && this.active_tab  >= 2 ) ? this.active_tab  + 1 : this.active_tab ;
			console.log(index, this.active_tab)
            
            switch (index) {
                case 0:
                    this.getData(); 
                    break;
                case 1:
                    this.getDataUnderReview(); 
                    break;
                case 2:
                    this.getDataApproved(); 
                    break;
                case 3:
                    this.getDataDispatched(); 
                    break;
                case 4:
                    this.getDataDelivered(); 
                    
                    break;
                case 5:
                    this.getDataCompleted(); 
                    
                    break;
                case 6:
                   this.getDataCancelled(); 
                    break;
                case 7:
                    this.getDataDeclined(); 
                    break;
                default:
                    break;
            }

	    	// this.getData(); 
			// this.getDataUnderReview();
			// this.getDataApproved();
			// this.getDataDeclined();
			// this.getDataDispatched();
			// this.getDataDelivered();
			// this.getDataCompleted();
			// this.getDataCancelled();
	    }, 

	    getData(){  
			console.log(this.filter, 'filter')
			this.all_isloading=true;

	    	var booking_type_id = this.filter.booking_type_id;
	    	if(booking_type_id == "all" || booking_type_id == null){
	    		booking_type_id = ""
	    	}
			
			var commodity_id = this.filter.commodity_id;
	    	if(commodity_id == "All" || commodity_id == null){
	    		commodity_id = ""
	    	}
	    	var customer_id = this.filter.customer_id;
	    	if(customer_id == "All" || customer_id == null){
	    		customer_id = ""
	    	} 
	    	var origin_id = this.filter.origin_id;
	    	if(origin_id == "All" || origin_id == null){
	    		origin_id = ""
	    	} 
			var vendor_id = this.filter.vendor_id;
	    	if(vendor_id == "All" || vendor_id == null){
	    		vendor_id = ""
	    	} 
			var depot_id = this.filter.depot_id;
	    	if(depot_id == "All" || depot_id == null){
	    		depot_id = ""
	    	} 

	    	var delivery_date_from = this.filter.delivery_date_from;
	    	if(delivery_date_from != ""){
	    		delivery_date_from = moment(delivery_date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
	    	}

	    	var delivery_date_to = this.filter.delivery_date_to;
	    	if(delivery_date_to != ""){
	    		delivery_date_to = moment(delivery_date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
	    		delivery_date_to = delivery_date_to + 86399; 
	    	}

	    	if(this.filter.booking_no != ""){
	    		delivery_date_from = "";
	    		delivery_date_to = "";
	    	}

			console.log(this.filter)

	    	axios.get(config.api_path+'/booking?booking_no='+this.filter.booking_no
			+'&booking_type_id='+booking_type_id
			+'&commodity_id='+commodity_id
			+'&customer_id='+customer_id
			+'&asset_type='+this.filter.asset_type
			+'&origin_id='+origin_id
			+'&depot_id='+depot_id
			+'&delivery_date_to='+delivery_date_to
			+'&delivery_date_from='+delivery_date_from
			+'&vendor_id='+vendor_id
			+'&client_ref_no='+this.filter.client_ref_no
			+'&page='+this.currentPage
			+'&limit=20&plate_no='+this.filter.plate_no)
	    	.then(response => {
	    		this.dataList = response.data; 
	    		this.dataList.data = this.dataList.data.map((value, index)=>{
	    			if(value.booking_type_id){
	    				value.booking_type_id_label = value.booking_type.setting_name
	    			}
	    			else{
	    				value.booking_type_id_label = "N/A"
	    			}
					if(value.commodity_id){
	    				value.commodity_id_label = value.commodity?.setting_name ?? '';
	    			}
	    			else{
	    				value.commodity_id_label = "N/A"
	    			}


	    			// if(value.customer_id){
	    			// 	value.customer_id_label = value.customer.customer_name
	    			// }
	    			// else{
	    			// 	value.customer_id_label = "N/A"
	    			// }
					value.customer_id_label = value.customer.customer_name? this.$options.filters.truncate(value.customer.customer_name,20):'-';

	    			if(value.origin_id){
	    				value.origin_id_label = value.origin.setting_name
	    			}
					else{
	    				value.origin_id_label = "N/A"
	    			}
					if(value.depot_id){
	    				value.depot_id_label = value.depot.setting_name
	    			}
	    			else{
	    				value.depot_id_label = "N/A"
	    			}

	    			if(value.destination_id){
	    				value.destination_id_label = value.destination.setting_name
	    			}
	    			else{
	    				value.destination_id_label = "N/A"
	    			}

	    			// if(value.vehicle_type_id){
    				if(value.is_subcon == false || value.is_subcon == 0){
    					value.vehicle_type_id_label = value.company.company_name;
    				}
    				else{
    					if(value.vendor){
    						value.vehicle_type_id_label = value.vendor.vendor_name	
    					}
    					else{
    						value.vehicle_type_id_label = "N/A"
    					}
    				}
	    			if(value.vehicle){
	    				value.plate_no = value.vehicle.plate_no
	    			}
	    			else{
	    				value.plate_no = "N/A"
	    			}

	    			if(value.delivery_date){
	    				value.delivery_date = value.delivery_date
	    			}
	    			else{
	    				value.delivery_date = "N/A"
	    			}
					value.client_ref_no_label = value.client_ref_no ? this.$options.filters.truncate(value.client_ref_no):'-';

	    			if(value.maker_model_id){
	    				value.maker_model_id_label = value.maker_model.setting_name
	    			}
	    			if(value.with_gps){
	    				value.with_gps_label = "Yes";
	    			}
	    			else{
	    				value.with_gps_label = "No";
	    			}

	    			if(value.delivery_date){
	    				value.delivery_date_label = moment.unix(value.delivery_date).format('MM/DD/YYYY')
	    			}
	    			else{
	    				value.delivery_date_label = "N/A"
	    			}

	    			if(value.asset_type == 'vehicle'){
	    				value.asset_no = value.vehicle ? value.vehicle.plate_no : "N/A"
	    			}
	    			if(value.asset_type == 'equipment'){
	    				value.asset_no = value.equipment ? value.equipment.equipment_prep_no : "N/A"
	    			}
	    			if(value.asset_type == 'furnishing'){
	    				value.asset_no = value.furnishing ? value.furnishing.asset_no : "N/A"
	    			}
	    			value.asset_type = value.asset_type.charAt(0).toUpperCase() + value.asset_type.slice(1)
	    			return value;
	    		}); 
				
	    		this.setCount()
	    	})
			.catch(err => {
				console.error(err)
				this.all_isloading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.all_isloading = false;	
			})
	    },

	    getDataUnderReview(){  
			this.isLoading = true;
			console.log(this.filter, 'sdsd')
	    	var booking_type_id = this.filter.booking_type_id;
	    	if(booking_type_id == "all" || booking_type_id == null){
	    		booking_type_id = ""
	    	}
			var commodity_id = this.filter.commodity_id;
	    	if(commodity_id == "All" || commodity_id == null){
	    		commodity_id = ""
	    	}
	    	var customer_id = this.filter.customer_id;
	    	if(customer_id == "All" || customer_id == null){
	    		customer_id = ""
	    	} 
	    	var origin_id = this.filter.origin_id;
	    	if(origin_id == "All" || origin_id == null){
	    		origin_id = ""
	    	} 
			var vendor_id = this.filter.vendor_id;
	    	if(vendor_id == "All" || vendor_id == null){
	    		vendor_id = ""
	    	} 
			var depot_id = this.filter.depot_id;
	    	if(depot_id == "All" || depot_id == null){
	    		depot_id = ""
	    	} 

	    	var delivery_date_from = this.filter.delivery_date_from;
	    	if(delivery_date_from != ""){
	    		delivery_date_from = moment(delivery_date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
	    	}

	    	var delivery_date_to = this.filter.delivery_date_to;
	    	if(delivery_date_to != ""){
	    		delivery_date_to = moment(delivery_date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
	    		delivery_date_to = delivery_date_to + 86399; 
	    	}

	    	if(this.filter.booking_no != ""){
	    		delivery_date_from = "";
	    		delivery_date_to = "";
	    	}

	    	axios.get(config.api_path+'/booking?status=under review&booking_no='
			+this.filter.booking_no
			+'&booking_type_id='+booking_type_id
			+'&commodity_id='+commodity_id
			+'&customer_id='+customer_id
			+'&asset_type='+this.filter.asset_type
			+'&origin_id='+origin_id
			+'&depot_id='+depot_id
			+'&delivery_date_to='+delivery_date_to
			+'&delivery_date_from='+delivery_date_from
			+'&vendor_id='+vendor_id
			+'&client_ref_no='+this.filter.client_ref_no
			+'&page='+this.currentPage
			+'&limit=20&plate_no='+this.filter.plate_no)
	    	.then(response => {
	    		this.dataListUnderReview = response.data; 
	    		this.dataListUnderReview.data = this.dataListUnderReview.data.map((value, index)=>{
	    			if(value.booking_type_id){
	    				value.booking_type_id_label = value.booking_type.setting_name
	    			}
	    			else{
	    				value.booking_type_id_label = "N/A"
	    			}
					if(value.commodity_id){
	    				value.commodity_id_label = value.commodity?.setting_name ?? '';
	    			}
	    			else{
	    				value.commodity_id_label = "N/A"
	    			}


	    			if(value.customer_id){
	    				value.customer_id_label = value.customer.customer_name
	    			}
	    			else{
	    				value.customer_id_label = "N/A"
	    			}

	    			if(value.origin_id){
	    				value.origin_id_label = value.origin.setting_name
	    			}
	    			else{
	    				value.origin_id_label = "N/A"
	    			}
					if(value.depot_id){
	    				value.depot_id_label = value.depot.setting_name
	    			}
	    			else{
	    				value.depot_id_label = "N/A"
	    			}

	    			if(value.destination_id){
	    				value.destination_id_label = value.destination.setting_name
	    			}
	    			else{
	    				value.destination_id_label = "N/A"
	    			}

	    			// if(value.vehicle_type_id){
    				if(value.is_subcon == false || value.is_subcon == 0){
    					value.vehicle_type_id_label = value.company.company_name;
    				}
    				else{
    					if(value.vendor){
    						value.vehicle_type_id_label = value.vendor.vendor_name	
    					}
    					else{
    						value.vehicle_type_id_label = "N/A"
    					}
    				}

    				// value.vehicle_type_id_label = value.vehicle_type.setting_name
	    			// }
	    			// else{
	    			// 	value.vehicle_type_id_label = "N/A"
	    			// }

	    			if(value.vehicle){
	    				value.plate_no = value.vehicle.plate_no
	    			}
	    			else{
	    				value.plate_no = "N/A"
	    			}

	    			if(value.delivery_date){
	    				value.delivery_date = value.delivery_date
	    			}
	    			else{
	    				value.delivery_date = "N/A"
	    			}
	    			// if(value.client_ref_no == "" || value.client_ref_no == null){
	    			// 	value.client_ref_no_label = "N/A";
	    			// }
	    			// else{
	    			// 	value.client_ref_no_label = value.client_ref_no;
	    			// }
					value.client_ref_no_label = value.client_ref_no ?? '';


	    			// if(value.vehicle_type_id){
	    			// 	value.vehicle_type_id_label = value.vehicle_type.setting_name
	    			// }
	    			if(value.maker_model_id){
	    				value.maker_model_id_label = value.maker_model.setting_name
	    			}
	    			if(value.with_gps){
	    				value.with_gps_label = "Yes";
	    			}
	    			else{
	    				value.with_gps_label = "No";
	    			}

	    			if(value.delivery_date){
	    				value.delivery_date_label = moment.unix(value.delivery_date).format('MM/DD/YYYY')
	    			}
	    			else{
	    				value.delivery_date_label = "N/A"
	    			}

	    			if(value.asset_type == 'vehicle'){
	    				value.asset_no = value.vehicle ? value.vehicle.plate_no : "N/A"
	    			}
	    			if(value.asset_type == 'equipment'){
	    				value.asset_no = value.equipment ? value.equipment.equipment_prep_no : "N/A"
	    			}
	    			if(value.asset_type == 'furnishing'){
	    				value.asset_no = value.furnishing ? value.furnishing.asset_no : "N/A"
	    			}
	    			value.asset_type = value.asset_type.charAt(0).toUpperCase() + value.asset_type.slice(1)
	    			return value;
	    		}); 
	    		this.setCount();
	    	})
			.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})
	    },

	    getDataApproved(){  

			this.isLoading=true;
	    	var booking_type_id = this.filter.booking_type_id;
	    	if(booking_type_id == "all" || booking_type_id == null){
	    		booking_type_id = ""
	    	}
			var commodity_id = this.filter.commodity_id;
	    	if(commodity_id == "All" || commodity_id == null){
	    		commodity_id = ""
	    	}
	    	var customer_id = this.filter.customer_id;
	    	if(customer_id == "All" || customer_id == null){
	    		customer_id = ""
	    	} 
	    	var origin_id = this.filter.origin_id;
	    	if(origin_id == "All" || origin_id == null){
	    		origin_id = ""
	    	} 
			var vendor_id = this.filter.vendor_id;
	    	if(vendor_id == "All" || vendor_id == null){
	    		vendor_id = ""
	    	} 
			var depot_id = this.filter.depot_id;
	    	if(depot_id == "All" || depot_id == null){
	    		depot_id = ""
	    	} 
	    	var delivery_date_from = this.filter.delivery_date_from;
	    	if(delivery_date_from != ""){
	    		delivery_date_from = moment(delivery_date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
	    	}

	    	var delivery_date_to = this.filter.delivery_date_to;
	    	if(delivery_date_to != ""){
	    		delivery_date_to = moment(delivery_date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
	    		delivery_date_to = delivery_date_to + 86399; 
	    	}

	    	if(this.filter.booking_no != ""){
	    		delivery_date_from = "";
	    		delivery_date_to = "";
	    	}

	    	axios.get(config.api_path+'/booking?status=approved&booking_no='
			+this.filter.booking_no
			+'&booking_type_id='+booking_type_id
			+'&commodity_id='+commodity_id
			+'&customer_id='+customer_id
			+'&asset_type='+this.filter.asset_type
			+'&origin_id='+origin_id
			+'&depot_id='+depot_id
			+'&delivery_date_to='+delivery_date_to
			+'&delivery_date_from='+delivery_date_from
			+'&vendor_id='+vendor_id
			+'&client_ref_no='+this.filter.client_ref_no
			+'&page='+this.currentPage
			+'&limit=20&plate_no='+this.filter.plate_no)
	    	.then(response => {
	    		this.dataListApproved = response.data; 
	    		this.dataListApproved.data = this.dataListApproved.data.map((value, index)=>{
	    			if(value.booking_type_id){
	    				value.booking_type_id_label = value.booking_type.setting_name
	    			}
	    			else{
	    				value.booking_type_id_label = "N/A"
	    			}

					if(value.commodity_id){
	    				value.commodity_id_label = value.commodity?.setting_name ?? '';
	    			}
	    			else{
	    				value.commodity_id_label = "N/A"
	    			}


	    			if(value.customer_id){
	    				value.customer_id_label = value.customer.customer_name
	    			}
	    			else{
	    				value.customer_id_label = "N/A"
	    			}

	    			if(value.origin_id){
	    				value.origin_id_label = value.origin.setting_name
	    			}
	    			else{
	    				value.origin_id_label = "N/A"
	    			}
					if(value.depot_id){
	    				value.depot_id_label = value.depot.setting_name
	    			}
	    			else{
	    				value.depot_id_label = "N/A"
	    			}

	    			if(value.destination_id){
	    				value.destination_id_label = value.destination.setting_name
	    			}
	    			else{
	    				value.destination_id_label = "N/A"
	    			}

	    			// if(value.vehicle_type_id){
    				if(value.is_subcon == false || value.is_subcon == 0){
    					value.vehicle_type_id_label = value.company.company_name;
    				}
    				else{
    					if(value.vendor){
    						value.vehicle_type_id_label = value.vendor.vendor_name	
    					}
    					else{
    						value.vehicle_type_id_label = "N/A"
    					}
    				}

    				// value.vehicle_type_id_label = value.vehicle_type.setting_name
	    			// }
	    			// else{
	    			// 	value.vehicle_type_id_label = "N/A"
	    			// }

	    			if(value.vehicle){
	    				value.plate_no = value.vehicle.plate_no
	    			}
	    			else{
	    				value.plate_no = "N/A"
	    			}

	    			if(value.delivery_date){
	    				value.delivery_date = value.delivery_date
	    			}
	    			else{
	    				value.delivery_date = "N/A"
	    			}
	    			// if(value.client_ref_no == "" || value.client_ref_no == null){
	    			// 	value.client_ref_no_label = "N/A";
	    			// }
	    			// else{
	    			// 	value.client_ref_no_label = value.client_ref_no;
	    			// }

					value.client_ref_no_label = value.client_ref_no ?? '';

	    			// if(value.vehicle_type_id){
	    			// 	value.vehicle_type_id_label = value.vehicle_type.setting_name
	    			// }
	    			if(value.maker_model_id){
	    				value.maker_model_id_label = value.maker_model.setting_name
	    			}
	    			if(value.with_gps){
	    				value.with_gps_label = "Yes";
	    			}
	    			else{
	    				value.with_gps_label = "No";
	    			}

	    			if(value.delivery_date){
	    				value.delivery_date_label = moment.unix(value.delivery_date).format('MM/DD/YYYY')
	    			}
	    			else{
	    				value.delivery_date_label = "N/A"
	    			}

	    			if(value.asset_type == 'vehicle'){
	    				value.asset_no = value.vehicle ? value.vehicle.plate_no : "N/A"
	    			}
	    			if(value.asset_type == 'equipment'){
	    				value.asset_no = value.equipment ? value.equipment.equipment_prep_no : "N/A"
	    			}
	    			if(value.asset_type == 'furnishing'){
	    				value.asset_no = value.furnishing ? value.furnishing.asset_no : "N/A"
	    			}
	    			value.asset_type = value.asset_type.charAt(0).toUpperCase() + value.asset_type.slice(1)
	    			return value;
	    		}); 
	    		this.setCount();
	    	})
			.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})
	    },

	    getDataDeclined(){  

			this.isLoading=true;
	    	var booking_type_id = this.filter.booking_type_id;
	    	if(booking_type_id == "all" || booking_type_id == null){
	    		booking_type_id = ""
	    	}
			var commodity_id = this.filter.commodity_id;
	    	if(commodity_id == "All" || commodity_id == null){
	    		commodity_id = ""
	    	}
	    	var customer_id = this.filter.customer_id;
	    	if(customer_id == "All" || customer_id == null){
	    		customer_id = ""
	    	} 
	    	var origin_id = this.filter.origin_id;
	    	if(origin_id == "All" || origin_id == null){
	    		origin_id = ""
	    	}
			var vendor_id = this.filter.vendor_id;
	    	if(vendor_id == "All" || vendor_id == null){
	    		vendor_id = ""
	    	}  
			var depot_id = this.filter.depot_id;
	    	if(depot_id == "All" || depot_id == null){
	    		depot_id = ""
	    	} 
	    	var delivery_date_from = this.filter.delivery_date_from;
	    	if(delivery_date_from != ""){
	    		delivery_date_from = moment(delivery_date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
	    	}

	    	var delivery_date_to = this.filter.delivery_date_to;
	    	if(delivery_date_to != ""){
	    		delivery_date_to = moment(delivery_date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
	    		delivery_date_to = delivery_date_to + 86399; 
	    	}

	    	if(this.filter.booking_no != ""){
	    		delivery_date_from = "";
	    		delivery_date_to = "";
	    	}

	    	axios.get(config.api_path+'/booking?status=declined&booking_no='
			+this.filter.booking_no
			+'&booking_type_id='+booking_type_id
			+'&commodity_id='+commodity_id
			+'&customer_id='+customer_id
			+'&asset_type='+this.filter.asset_type
			+'&origin_id='+origin_id
			+'&depot_id='+depot_id
			+'&delivery_date_to='+delivery_date_to
			+'&delivery_date_from='+delivery_date_from
			+'&vendor_id='+vendor_id
			+'&client_ref_no='+this.filter.client_ref_no
			+'&page='+this.currentPage
			+'&limit=20&plate_no='+this.filter.plate_no)
	    	.then(response => {
	    		this.dataListDeclined = response.data; 
	    		this.dataListDeclined.data = this.dataListDeclined.data.map((value, index)=>{
	    			if(value.booking_type_id){
	    				value.booking_type_id_label = value.booking_type.setting_name
	    			}
	    			else{
	    				value.booking_type_id_label = "N/A"
	    			}
					if(value.commodity_id){
	    				value.commodity_id_label = value.commodity?.setting_name ?? '';
	    			}
	    			else{
	    				value.commodity_id_label = "N/A"
	    			}

	    			if(value.customer_id){
	    				value.customer_id_label = value.customer.customer_name
	    			}
	    			else{
	    				value.customer_id_label = "N/A"
	    			}

	    			if(value.origin_id){
	    				value.origin_id_label = value.origin.setting_name
	    			}
	    			else{
	    				value.origin_id_label = "N/A"
	    			}
					if(value.depot_id){
	    				value.depot_id_label = value.depot.setting_name
	    			}
	    			else{
	    				value.depot_id_label = "N/A"
	    			}

	    			if(value.destination_id){
	    				value.destination_id_label = value.destination.setting_name
	    			}
	    			else{
	    				value.destination_id_label = "N/A"
	    			}

	    			// if(value.vehicle_type_id){
    				if(value.is_subcon == false || value.is_subcon == 0){
    					value.vehicle_type_id_label = value.company.company_name;
    				}
    				else{
    					if(value.vendor){
    						value.vehicle_type_id_label = value.vendor.vendor_name	
    					}
    					else{
    						value.vehicle_type_id_label = "N/A"
    					}
    				}

    				// value.vehicle_type_id_label = value.vehicle_type.setting_name
	    			// }
	    			// else{
	    			// 	value.vehicle_type_id_label = "N/A"
	    			// }

	    			if(value.vehicle){
	    				value.plate_no = value.vehicle.plate_no
	    			}
	    			else{
	    				value.plate_no = "N/A"
	    			}

	    			if(value.delivery_date){
	    				value.delivery_date = value.delivery_date
	    			}
	    			else{
	    				value.delivery_date = "N/A"
	    			}
	    			// if(value.client_ref_no == "" || value.client_ref_no == null){
	    			// 	value.client_ref_no_label = "N/A";
	    			// }
	    			// else{
	    			// 	value.client_ref_no_label = value.client_ref_no;
	    			// }
					value.client_ref_no_label = value.client_ref_no ?? '';


	    			// if(value.vehicle_type_id){
	    			// 	value.vehicle_type_id_label = value.vehicle_type.setting_name
	    			// }
	    			if(value.maker_model_id){
	    				value.maker_model_id_label = value.maker_model.setting_name
	    			}
	    			if(value.with_gps){
	    				value.with_gps_label = "Yes";
	    			}
	    			else{
	    				value.with_gps_label = "No";
	    			}

	    			if(value.delivery_date){
	    				value.delivery_date_label = moment.unix(value.delivery_date).format('MM/DD/YYYY')
	    			}
	    			else{
	    				value.delivery_date_label = "N/A"
	    			}

	    			if(value.asset_type == 'vehicle'){
	    				value.asset_no = value.vehicle ? value.vehicle.plate_no : "N/A"
	    			}
	    			if(value.asset_type == 'equipment'){
	    				value.asset_no = value.equipment ? value.equipment.equipment_prep_no : "N/A"
	    			}
	    			if(value.asset_type == 'furnishing'){
	    				value.asset_no = value.furnishing ? value.furnishing.asset_no : "N/A"
	    			}
	    			value.asset_type = value.asset_type.charAt(0).toUpperCase() + value.asset_type.slice(1)
	    			return value;
	    		}); 
	    		this.setCount();
	    	})
			.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})
	    },

	    getDataDispatched(){  
			this.isLoading=true;

	    	var booking_type_id = this.filter.booking_type_id;
	    	if(booking_type_id == "all" || booking_type_id == null){
	    		booking_type_id = ""
	    	}
			var commodity_id = this.filter.commodity_id;
	    	if(commodity_id == "All" || commodity_id == null){
	    		commodity_id = ""
	    	}
	    	var customer_id = this.filter.customer_id;
	    	if(customer_id == "All" || customer_id == null){
	    		customer_id = ""
	    	} 
			
	    	var origin_id = this.filter.origin_id;
	    	if(origin_id == "All" || origin_id == null){
	    		origin_id = ""
	    	} 
			var vendor_id = this.filter.vendor_id;
	    	if(vendor_id == "All" || vendor_id == null){
	    		vendor_id = ""
	    	} 
			var depot_id = this.filter.depot_id;
	    	if(depot_id == "All" || depot_id == null){
	    		depot_id = ""
	    	} 

	    	var delivery_date_from = this.filter.delivery_date_from;
	    	if(delivery_date_from != ""){
	    		delivery_date_from = moment(delivery_date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
	    	}

	    	var delivery_date_to = this.filter.delivery_date_to;
	    	if(delivery_date_to != ""){
	    		delivery_date_to = moment(delivery_date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
	    		delivery_date_to = delivery_date_to + 86399; 
	    	}

	    	if(this.filter.booking_no != ""){
	    		delivery_date_from = "";
	    		delivery_date_to = "";
	    	}

	    	axios.get(config.api_path+'/booking?status=dispatched&booking_no='
			+this.filter.booking_no
			+'&booking_type_id='+booking_type_id
			+'&customer_id='+customer_id
			+'&commodity_id='+commodity_id
			+'&asset_type='+this.filter.asset_type
			+'&origin_id='+origin_id
			+'&depot_id='+depot_id
			+'&delivery_date_to='+delivery_date_to
			+'&delivery_date_from='+delivery_date_from
			+'&vendor_id='+vendor_id
			+'&client_ref_no='+this.filter.client_ref_no
			+'&page='+this.currentPage
			+'&limit=20&plate_no='+this.filter.plate_no)
	    	.then(response => {
	    		this.dataListDispatched = response.data; 
	    		this.dataListDispatched.data = this.dataListDispatched.data.map((value, index)=>{
	    			if(value.booking_type_id){
	    				value.booking_type_id_label = value.booking_type.setting_name
	    			}
	    			else{
	    				value.booking_type_id_label = "N/A"
	    			}
					if(value.commodity_id){
	    				value.commodity_id_label = value.commodity?.setting_name ?? '';
	    			}
	    			else{
	    				value.commodity_id_label = "N/A"
	    			}

	    			if(value.customer_id){
	    				value.customer_id_label = value.customer.customer_name
	    			}
	    			else{
	    				value.customer_id_label = "N/A"
	    			}

	    			if(value.origin_id){
	    				value.origin_id_label = value.origin.setting_name
	    			}
	    			else{
	    				value.origin_id_label = "N/A"
	    			}
					if(value.depot_id){
	    				value.depot_id_label = value.depot.setting_name
	    			}
	    			else{
	    				value.depot_id_label = "N/A"
	    			}

	    			if(value.destination_id){
	    				value.destination_id_label = value.destination.setting_name
	    			}
	    			else{
	    				value.destination_id_label = "N/A"
	    			}

	    			// if(value.vehicle_type_id){
    				if(value.is_subcon == false || value.is_subcon == 0){
    					value.vehicle_type_id_label = value.company.company_name;
    				}
    				else{
    					if(value.vendor){
    						value.vehicle_type_id_label = value.vendor.vendor_name	
    					}
    					else{
    						value.vehicle_type_id_label = "N/A"
    					}
    				}

    				// value.vehicle_type_id_label = value.vehicle_type.setting_name
	    			// }
	    			// else{
	    			// 	value.vehicle_type_id_label = "N/A"
	    			// }

	    			if(value.vehicle){
	    				value.plate_no = value.vehicle.plate_no
	    			}
	    			else{
	    				value.plate_no = "N/A"
	    			}

	    			if(value.delivery_date){
	    				value.delivery_date = value.delivery_date
	    			}
	    			else{
	    				value.delivery_date = "N/A"
	    			}
	    			// if(value.client_ref_no == "" || value.client_ref_no == null){
	    			// 	value.client_ref_no_label = "N/A";
	    			// }
	    			// else{
	    			// 	value.client_ref_no_label = value.client_ref_no;
	    			// }
					value.client_ref_no_label = value.client_ref_no ?? '';


	    			// if(value.vehicle_type_id){
	    			// 	value.vehicle_type_id_label = value.vehicle_type.setting_name
	    			// }
	    			if(value.maker_model_id){
	    				value.maker_model_id_label = value.maker_model.setting_name
	    			}
	    			if(value.with_gps){
	    				value.with_gps_label = "Yes";
	    			}
	    			else{
	    				value.with_gps_label = "No";
	    			}

	    			if(value.delivery_date){
	    				value.delivery_date_label = moment.unix(value.delivery_date).format('MM/DD/YYYY')
	    			}
	    			else{
	    				value.delivery_date_label = "N/A"
	    			}

	    			if(value.asset_type == 'vehicle'){
	    				value.asset_no = value.vehicle ? value.vehicle.plate_no : "N/A"
	    			}
	    			if(value.asset_type == 'equipment'){
	    				value.asset_no = value.equipment ? value.equipment.equipment_prep_no : "N/A"
	    			}
	    			if(value.asset_type == 'furnishing'){
	    				value.asset_no = value.furnishing ? value.furnishing.asset_no : "N/A"
	    			}
	    			value.asset_type = value.asset_type.charAt(0).toUpperCase() + value.asset_type.slice(1)
	    			return value;
	    		}); 
	    		this.setCount();
	    	})
			.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})
	    },

	    getDataDelivered(){  

		this.isLoading=true;
	    	var booking_type_id = this.filter.booking_type_id;
	    	if(booking_type_id == "all" || booking_type_id == null){
	    		booking_type_id = ""
	    	}
			var commodity_id = this.filter.commodity_id;
	    	if(commodity_id == "All" || commodity_id == null){
	    		commodity_id = ""
	    	}
	    	var customer_id = this.filter.customer_id;
	    	if(customer_id == "All" || customer_id == null){
	    		customer_id = ""
	    	} 
	    	var origin_id = this.filter.origin_id;
	    	if(origin_id == "All" || origin_id == null){
	    		origin_id = ""
	    	} 
			var vendor_id = this.filter.vendor_id;
	    	if(vendor_id == "All" || vendor_id == null){
	    		vendor_id = ""
	    	} 
			var depot_id = this.filter.depot_id;
	    	if(depot_id == "All" || depot_id == null){
	    		depot_id = ""
	    	} 
	    	var delivery_date_from = this.filter.delivery_date_from;
	    	if(delivery_date_from != ""){
	    		delivery_date_from = moment(delivery_date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
	    	}

	    	var delivery_date_to = this.filter.delivery_date_to;
	    	if(delivery_date_to != ""){
	    		delivery_date_to = moment(delivery_date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
	    		delivery_date_to = delivery_date_to + 86399; 
	    	}

	    	if(this.filter.booking_no != ""){
	    		delivery_date_from = "";
	    		delivery_date_to = "";
	    	}

	    	axios.get(config.api_path+'/booking?status=delivered&booking_no='
			+this.filter.booking_no
			+'&booking_type_id='+booking_type_id
			+'&commodity_id='+commodity_id
			+'&customer_id='+customer_id
			+'&asset_type='+this.filter.asset_type
			+'&origin_id='+origin_id
			+'&depot_id='+depot_id
			+'&delivery_date_to='+delivery_date_to
			+'&delivery_date_from='+delivery_date_from
			+'&vendor_id='+vendor_id
			+'&client_ref_no='+this.filter.client_ref_no
			+'&page='+this.currentPage
			+'&limit=20&plate_no='+this.filter.plate_no)

	    	.then(response => {
	    		this.dataListDelivered = response.data; 
	    		this.dataListDelivered.data = this.dataListDelivered.data.map((value, index)=>{
	    			if(value.booking_type_id){
	    				value.booking_type_id_label = value.booking_type.setting_name
	    			}
	    			else{
	    				value.booking_type_id_label = "N/A"
	    			}
					if(value.commodity_id){
	    				value.commodity_id_label = value.commodity?.setting_name ?? '';
	    			}
	    			else{
	    				value.commodity_id_label = "N/A"
	    			}


	    			if(value.customer_id){
	    				value.customer_id_label = value.customer.customer_name
	    			}
	    			else{
	    				value.customer_id_label = "N/A"
	    			}

	    			if(value.origin_id){
	    				value.origin_id_label = value.origin.setting_name
	    			}
	    			else{
	    				value.origin_id_label = "N/A"
	    			}
					if(value.depot_id){
	    				value.depot_id_label = value.depot.setting_name
	    			}
	    			else{
	    				value.depot_id_label = "N/A"
	    			}

	    			if(value.destination_id){
	    				value.destination_id_label = value.destination.setting_name
	    			}
	    			else{
	    				value.destination_id_label = "N/A"
	    			}

	    			// if(value.vehicle_type_id){
    				if(value.is_subcon == false || value.is_subcon == 0){
    					value.vehicle_type_id_label = value.company.company_name;
    				}
    				else{
    					if(value.vendor){
    						value.vehicle_type_id_label = value.vendor.vendor_name	
    					}
    					else{
    						value.vehicle_type_id_label = "N/A"
    					}
    				}

    				// value.vehicle_type_id_label = value.vehicle_type.setting_name
	    			// }
	    			// else{
	    			// 	value.vehicle_type_id_label = "N/A"
	    			// }

	    			if(value.vehicle){
	    				value.plate_no = value.vehicle.plate_no
	    			}
	    			else{
	    				value.plate_no = "N/A"
	    			}

	    			if(value.delivery_date){
	    				value.delivery_date = value.delivery_date
	    			}
	    			else{
	    				value.delivery_date = "N/A"
	    			}
	    			// if(value.client_ref_no == "" || value.client_ref_no == null){
	    			// 	value.client_ref_no_label = "N/A";
	    			// }
	    			// else{
	    			// 	value.client_ref_no_label = value.client_ref_no;
	    			// }
					value.client_ref_no_label = value.client_ref_no ?? '';

	    			// if(value.vehicle_type_id){
	    			// 	value.vehicle_type_id_label = value.vehicle_type.setting_name
	    			// }
	    			if(value.maker_model_id){
	    				value.maker_model_id_label = value.maker_model.setting_name
	    			}
	    			if(value.with_gps){
	    				value.with_gps_label = "Yes";
	    			}
	    			else{
	    				value.with_gps_label = "No";
	    			}

	    			if(value.delivery_date){
	    				value.delivery_date_label = moment.unix(value.delivery_date).format('MM/DD/YYYY')
	    			}
	    			else{
	    				value.delivery_date_label = "N/A"
	    			}

	    			if(value.asset_type == 'vehicle'){
	    				value.asset_no = value.vehicle ? value.vehicle.plate_no : "N/A"
	    			}
	    			if(value.asset_type == 'equipment'){
	    				value.asset_no = value.equipment ? value.equipment.equipment_prep_no : "N/A"
	    			}
	    			if(value.asset_type == 'furnishing'){
	    				value.asset_no = value.furnishing ? value.furnishing.asset_no : "N/A"
	    			}
	    			value.asset_type = value.asset_type.charAt(0).toUpperCase() + value.asset_type.slice(1)
	    			return value;
	    		}); 
	    		this.setCount();
	    	})
			.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})
	    },

	    getDataCompleted(){ 
			this.isLoading=true; 

	    	var booking_type_id = this.filter.booking_type_id;
	    	if(booking_type_id == "all" || booking_type_id == null){
	    		booking_type_id = ""
	    	}
			var commodity_id = this.filter.commodity_id;
	    	if(commodity_id == "All" || commodity_id == null){
	    		commodity_id = ""
	    	}
	    	var customer_id = this.filter.customer_id;
	    	if(customer_id == "All" || customer_id == null){
	    		customer_id = ""
	    	} 
	    	var origin_id = this.filter.origin_id;
	    	if(origin_id == "All" || origin_id == null){
	    		origin_id = ""
	    	} 
			var depot_id = this.filter.depot_id;
	    	if(depot_id == "All" || depot_id == null){
	    		depot_id = ""
	    	} 
			var vendor_id = this.filter.vendor_id;
	    	if(vendor_id == "All" || vendor_id == null){
	    		vendor_id = ""
	    	} 

	    	var delivery_date_from = this.filter.delivery_date_from;
	    	if(delivery_date_from != ""){
	    		delivery_date_from = moment(delivery_date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
	    	}

	    	var delivery_date_to = this.filter.delivery_date_to;
	    	if(delivery_date_to != ""){
	    		delivery_date_to = moment(delivery_date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
	    		delivery_date_to = delivery_date_to + 86399; 
	    	}

	    	if(this.filter.booking_no != ""){
	    		delivery_date_from = "";
	    		delivery_date_to = "";
	    	}

	    	axios.get(config.api_path+'/booking?status=completed&booking_no='
			+this.filter.booking_no
			+'&booking_type_id='+booking_type_id
			+'&customer_id='+customer_id
			+'&commodity_id='+commodity_id
			+'&asset_type='+this.filter.asset_type
			+'&origin_id='+origin_id
			+'&depot_id='+depot_id
			+'&delivery_date_to='+delivery_date_to
			+'&delivery_date_from='+delivery_date_from
			+'&vendor_id='+vendor_id
			+'&client_ref_no='+this.filter.client_ref_no
			+'&page='+this.currentPage
			+'&limit=20&plate_no='+this.filter.plate_no)

	    	.then(response => {
	    		this.dataListCompleted = response.data; 
	    		this.dataListCompleted.data = this.dataListCompleted.data.map((value, index)=>{
	    			if(value.booking_type_id){
	    				value.booking_type_id_label = value.booking_type.setting_name
	    			}
	    			else{
	    				value.booking_type_id_label = "N/A"
	    			}
					if(value.commodity_id){
	    				value.commodity_id_label = value.commodity?.setting_name ?? '';
	    			}
	    			else{
	    				value.commodity_id_label = "N/A"
	    			}


	    			if(value.customer_id){
	    				value.customer_id_label = value.customer.customer_name
	    			}
	    			else{
	    				value.customer_id_label = "N/A"
	    			}

	    			if(value.origin_id){
	    				value.origin_id_label = value.origin.setting_name
	    			}
	    			else{
	    				value.origin_id_label = "N/A"
	    			}
					if(value.depot_id){
	    				value.depot_id_label = value.depot.setting_name
	    			}
	    			else{
	    				value.depot_id_label = "N/A"
	    			}

	    			if(value.destination_id){
	    				value.destination_id_label = value.destination.setting_name
	    			}
	    			else{
	    				value.destination_id_label = "N/A"
	    			}

	    			// if(value.vehicle_type_id){
    				if(value.is_subcon == false || value.is_subcon == 0){
    					value.vehicle_type_id_label = value.company.company_name;
    				}
    				else{
    					if(value.vendor){
    						value.vehicle_type_id_label = value.vendor.vendor_name	
    					}
    					else{
    						value.vehicle_type_id_label = "N/A"
    					}
    				}

    				// value.vehicle_type_id_label = value.vehicle_type.setting_name
	    			// }
	    			// else{
	    			// 	value.vehicle_type_id_label = "N/A"
	    			// }

	    			if(value.vehicle){
	    				value.plate_no = value.vehicle.plate_no
	    			}
	    			else{
	    				value.plate_no = "N/A"
	    			}

	    			if(value.delivery_date){
	    				value.delivery_date = value.delivery_date
	    			}
	    			else{
	    				value.delivery_date = "N/A"
	    			}
	    			// if(value.client_ref_no == "" || value.client_ref_no == null){
	    			// 	value.client_ref_no_label = "N/A";
	    			// }
	    			// else{
	    			// 	value.client_ref_no_label = value.client_ref_no;
	    			// }
					value.client_ref_no_label = value.client_ref_no ?? '';


	    			// if(value.vehicle_type_id){
	    			// 	value.vehicle_type_id_label = value.vehicle_type.setting_name
	    			// }
	    			if(value.maker_model_id){
	    				value.maker_model_id_label = value.maker_model.setting_name
	    			}
	    			if(value.with_gps){
	    				value.with_gps_label = "Yes";
	    			}
	    			else{
	    				value.with_gps_label = "No";
	    			}

	    			if(value.delivery_date){
	    				value.delivery_date_label = moment.unix(value.delivery_date).format('MM/DD/YYYY')
	    			}
	    			else{
	    				value.delivery_date_label = "N/A"
	    			}

	    			if(value.asset_type == 'vehicle'){
	    				value.asset_no = value.vehicle ? value.vehicle.plate_no : "N/A"
	    			}
	    			if(value.asset_type == 'equipment'){
	    				value.asset_no = value.equipment ? value.equipment.equipment_prep_no : "N/A"
	    			}
	    			if(value.asset_type == 'furnishing'){
	    				value.asset_no = value.furnishing ? value.furnishing.asset_no : "N/A"
	    			}
	    			value.asset_type = value.asset_type.charAt(0).toUpperCase() + value.asset_type.slice(1)
	    			return value;
	    		}); 
	    		this.setCount();
	    	})
			.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})
	    },

	    getDataCancelled(){  
			this.isLoading=true;

	    	var booking_type_id = this.filter.booking_type_id;
	    	if(booking_type_id == "all" || booking_type_id == null){
	    		booking_type_id = ""
	    	}
			var commodity_id = this.filter.commodity_id;
	    	if(commodity_id == "All" || commodity_id == null){
	    		commodity_id = ""
	    	}
	    	var customer_id = this.filter.customer_id;
	    	if(customer_id == "All" || customer_id == null){
	    		customer_id = ""
	    	} 
	    	var origin_id = this.filter.origin_id;
	    	if(origin_id == "All" || origin_id == null){
	    		origin_id = ""
	    	} 
			var depot_id = this.filter.depot_id;
	    	if(depot_id == "All" || depot_id == null){
	    		depot_id = ""
	    	} 
			var vendor_id = this.filter.vendor_id;
	    	if(vendor_id == "All" || vendor_id == null){
	    		vendor_id = ""
	    	} 
	    	var delivery_date_from = this.filter.delivery_date_from;
	    	if(delivery_date_from != ""){
	    		delivery_date_from = moment(delivery_date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
	    	}

	    	var delivery_date_to = this.filter.delivery_date_to;
	    	if(delivery_date_to != ""){
	    		delivery_date_to = moment(delivery_date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
	    		delivery_date_to = delivery_date_to + 86399; 
	    	}

	    	if(this.filter.booking_no != ""){
	    		delivery_date_from = "";
	    		delivery_date_to = "";
	    	}

	    	axios.get(config.api_path+'/booking?status=cancelled&booking_no='
			+this.filter.booking_no
			+'&booking_type_id='+booking_type_id
			+'&commodity_id='+commodity_id
			+'&customer_id='+customer_id
			+'&asset_type='+this.filter.asset_type
			+'&origin_id='+origin_id
			+'&depot_id='+depot_id
			+'&delivery_date_to='+delivery_date_to
			+'&delivery_date_from='+delivery_date_from
			+'&vendor_id='+vendor_id
			+'&client_ref_no='+this.filter.client_ref_no
			+'&page='+this.currentPage
			+'&limit=20&plate_no='+this.filter.plate_no)
	    	.then(response => {
	    		this.dataListCancelled = response.data; 
	    		this.dataListCancelled.data = this.dataListCancelled.data.map((value, index)=>{
	    			if(value.booking_type_id){
	    				value.booking_type_id_label = value.booking_type.setting_name
	    			}
	    			else{
	    				value.booking_type_id_label = "N/A"
	    			}
					if(value.commodity_id){
	    				value.commodity_id_label = value.commodity?.setting_name ?? '';
	    			}
	    			else{
	    				value.commodity_id_label = "N/A"
	    			}


	    			if(value.customer_id){
	    				value.customer_id_label = value.customer.customer_name
	    			}
	    			else{
	    				value.customer_id_label = "N/A"
	    			}

	    			if(value.origin_id){
	    				value.origin_id_label = value.origin.setting_name
	    			}
	    			else{
	    				value.origin_id_label = "N/A"
	    			}
					if(value.depot_id){
	    				value.depot_id_label = value.depot.setting_name
	    			}
	    			else{
	    				value.depot_id_label = "N/A"
	    			}

	    			if(value.destination_id){
	    				value.destination_id_label = value.destination.setting_name
	    			}
	    			else{
	    				value.destination_id_label = "N/A"
	    			}

	    			// if(value.vehicle_type_id){
    				if(value.is_subcon == false || value.is_subcon == 0){
    					value.vehicle_type_id_label = value.company.company_name;
    				}
    				else{
    					if(value.vendor){
    						value.vehicle_type_id_label = value.vendor.vendor_name	
    					}
    					else{
    						value.vehicle_type_id_label = "N/A"
    					}
    				}

    				// value.vehicle_type_id_label = value.vehicle_type.setting_name
	    			// }
	    			// else{
	    			// 	value.vehicle_type_id_label = "N/A"
	    			// }

	    			if(value.vehicle){
	    				value.plate_no = value.vehicle.plate_no
	    			}
	    			else{
	    				value.plate_no = "N/A"
	    			}

	    			if(value.delivery_date){
	    				value.delivery_date = value.delivery_date
	    			}
	    			else{
	    				value.delivery_date = "N/A"
	    			}
	    			// if(value.client_ref_no == "" || value.client_ref_no == null){
	    			// 	value.client_ref_no_label = "N/A";
	    			// }
	    			// else{
	    			// 	value.client_ref_no_label = value.client_ref_no;
	    			// }
					value.client_ref_no_label = value.client_ref_no ?? '';


	    			// if(value.vehicle_type_id){
	    			// 	value.vehicle_type_id_label = value.vehicle_type.setting_name
	    			// }
	    			if(value.maker_model_id){
	    				value.maker_model_id_label = value.maker_model.setting_name
	    			}
	    			if(value.with_gps){
	    				value.with_gps_label = "Yes";
	    			}
	    			else{
	    				value.with_gps_label = "No";
	    			}

	    			if(value.delivery_date){
	    				value.delivery_date_label = moment.unix(value.delivery_date).format('MM/DD/YYYY')
	    			}
	    			else{
	    				value.delivery_date_label = "N/A"
	    			}

	    			if(value.asset_type == 'vehicle'){
	    				value.asset_no = value.vehicle ? value.vehicle.plate_no : "N/A"
	    			}
	    			if(value.asset_type == 'equipment'){
	    				value.asset_no = value.equipment ? value.equipment.equipment_prep_no : "N/A"
	    			}
	    			if(value.asset_type == 'furnishing'){
	    				value.asset_no = value.furnishing ? value.furnishing.asset_no : "N/A"
	    			}
	    			value.asset_type = value.asset_type.charAt(0).toUpperCase() + value.asset_type.slice(1)
	    			return value;
	    		}); 
	    		this.setCount();
	    	})
			.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})
	    },




	    // updateModal(item){  alert()
	    	// this.$router.push('/data/update_vehicle/'+item.id) 
	    	// window.open('/data/update_vehicle/'+item.id, '_blank');
	    // },

	    viewModal(item){ 
	    	// this.$router.push('/main/view_booking/'+item.id) 
					window.open('/#/main/view_booking/'+item.id, '_blank');
	    }, 

	    getVendor(){

	    	axios.get(config.api_path+'/vendor?page=1&limit=10000000')
	    	.then(response => {
	    		this.vendorList = response.data;
	    		this.vendorList.data = this.vendorList.data.map((value, index)=>{
	    			value.value = value.id;
	    			value.label = value.vendor_name;
	    			return value;
	    		})
	    	})

	    },

	    getDepot(){

	    	axios.get(config.api_path+'/setting?setting_type=depot&page=1&limit=10000000')
	    	.then(response => {
	    		this.depotList = response.data;
	    		this.depotList.data = this.depotList.data.map((value, index)=>{
	    			value.value = value.id;
	    			value.label = value.setting_name;
	    			return value;
	    		})
	    	})

	    }, 

	    // cancelBooking(item){
	    // 	Swal.fire({
		// 		icon:'warning',
		// 		title: 'Are you sure you want to cancel this booking?', 
		// 		showCancelButton: true,
		// 		confirmButtonText: `Yes`, 
		// 	}).then((result) => { 
		// 		if (result.isConfirmed) {
		// 			Swal.fire({
		// 			  title: 'Cancellation Reason',
		// 			  input: 'text',
		// 			  inputAttributes: {
		// 			    autocapitalize: 'off',
		// 			    required: true
		// 			  },
		// 			  showCancelButton: true,
		// 			  confirmButtonText: 'Save',
		// 			  showLoaderOnConfirm: true,
		// 			  preConfirm: (cancellation_reason) => {
		// 			  	return axios.put(config.api_path+'/booking/'+item.id, {
		// 			  		status: "cancelled",
		// 					user_id: config.getUserDetail('id'),
		// 					status_update: true,
		// 					cancellation_reason
		// 			  	})
		// 		    	.then(response => {
				    		
		// 		    		if (response.status == "success") {
		// 			          throw new Error(response.statusText)
		// 			        }
		// 			        return response;
		// 		    	}) 
		// 		    	.catch(error => {
		// 			        Swal.showValidationMessage(
		// 			          `Request failed: ${error}`
		// 			        )
		// 			      }) 
					      
		// 			  },
		// 			  allowOutsideClick: () => !Swal.isLoading()
		// 			}).then((result) => {
		// 			  if (result.isConfirmed) {
		// 			  	this.getData(); 
		// 			    Swal.fire({
		// 					title: 'Success!',
		// 					text: "Booking successfully cancelled.",
		// 					icon: 'success', 
		// 				})
		// 			  }
		// 			})
					
		// 		}
		// 	})  
	    // },

	    // confirmDelete(item){
	    // 	Swal.fire({
		// 		icon:'warning',
		// 		title: 'Are you sure you want to delete '+item.booking_no+"?", 
		// 		showCancelButton: true,
		// 		confirmButtonText: `Yes`, 
		// 	}).then((result) => { 
		// 		if (result.isConfirmed) {
					
		// 			axios.delete(config.api_path+'/booking/'+item.id)
		// 			.then(response=>{
		// 				Swal.fire({
		// 					title: 'Success!',
		// 					text: "Booking "+item.booking_no+" successfully deleted.",
		// 					icon: 'success', 
		// 				})
		// 				this.getData(); 
		// 				this.getDataUnderReview();
		// 				this.getDataApproved();
		// 				this.getDataDeclined();
		// 				this.getDataDispatched();
		// 				this.getDataDelivered();
		// 				this.getDataCompleted();
		// 				this.getDataCancelled();
		// 			})
					
		// 		}
		// 	})  
	    // },

	    // openApproveModal(item){
	    // 	this.approveModal = true;
	    // 	this.selectedRow = item;
	    // },

	    // openDispatchModal(item){
	    // 	this.dispatchModal = true;
	    // 	this.selectedRow = item;
	    // },

	    // openDeliverModal(item){
	    // 	this.deliverModal = true;
	    // 	this.selectedRow = item;
	    // },

	    // openCompleteModal(item){
	    // 	this.completeModal = true;
	    // 	this.selectedRow = item;
	    // },

	    // updateModal(item){
	    // 	// this.$router.push('/main/update_booking/'+item.id)
	    // 	window.open('/#/main/update_booking/'+item.id, '_blank');
	    // },

	    download(type = 'xlxs'){

		var delivery_date_from = this.filter.delivery_date_from;
	    	if(delivery_date_from != ""){
	    		delivery_date_from = moment(delivery_date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
	    	}

	    	var delivery_date_to = this.filter.delivery_date_to;
	    	if(delivery_date_to != ""){
	    		delivery_date_to = moment(delivery_date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
	    		delivery_date_to = delivery_date_to + 86399; 
	    	}

	    	var booking_type_id = this.filter.booking_type_id;
	    	if(booking_type_id == "all" || booking_type_id == null){
	    		booking_type_id = ""
	    	}
	    	var customer_id = this.filter.customer_id;
	    	if(customer_id == "all" || customer_id == null){
	    		customer_id = ""
	    	} 
			
			
	    	var origin_id = this.filter.origin_id;
	    	if(origin_id == "all" || origin_id == null){
	    		origin_id = ""
	    	} 
			var commodity_id = this.filter.commodity_id;
	    	if(commodity_id == "all" || commodity_id == null){
	    		commodity_id = ""
	    	}
			var depot_id = this.filter.depot_id;
	    	if(depot_id == "all" || depot_id == null){
	    		depot_id = ""
	    	} 
	    	var vendor_id = this.filter.vendor_id;
	    	if(vendor_id == "all" || vendor_id == null){
	    		vendor_id = ""
	    	} 
			let status = this.booking_status;

			this.$showLoading(true)
			axios.get(`${config.api_path}/reports/booking`,{
				params: {
					booking_no: this.filter.booking_no,
					booking_type_id,
					commodity_id,
					customer_id,
					asset_type : this.filter.asset_type,
					origin_id,
					depot_id,
					delivery_date_from,
					delivery_date_to,
					vendor_id,
					client_ref_no: this.filter.client_ref_no,
					status,
					type
				}
			})
	    	// axios.get(config.api_path+'/reports/booking?booking_no='+this.filter.booking_no
			// +'&booking_type_id='+booking_type_id
			// +'&customer_id='+customer_id
			// +'&asset_type='+this.filter.asset_type
			// +'&origin_id='+origin_id
			// +'&depot_id='+depot_id
			// +'&delivery_date_to='+delivery_date_to
			// +'&delivery_date_from='+delivery_date_from
			// // +'&created_at_from='+this.filter.created_at_from
			// // +'&created_at_to='+this.filter.created_at_to
			// +'&vendor_id='+vendor_id
			// +'&client_ref_no='+this.filter.client_ref_no+'&status='+status)

	    	.then(response=>{
				this.$showLoading(false)
	    		window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file) 
				setTimeout(() => {
					axios.get(`${config.api_path}/remove-file`,{
						params : {
							filename : response.data.file
						}
					})	
				}, 2000);

	    	})
			.catch(err => {
				this.$showLoading(false)
			})
	    	
			
	    }
 	}
}
</script>
